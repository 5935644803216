import { createAppSlice } from '.';
import {
  addMetadataToTaskType,
  addTaskType,
  deleteTaskType as deleteApiTaskType,
  getTaskTypes,
  removeMetadataFromTaskType,
  updateTaskType as updateApiTaskType,
  updateMetadataInTaskType,
} from '../api/api';
import { TASK_TYPE_STATUS, TaskTypeStatus } from '../constants/taskTypeConstants';
import { ItemWithPermissions } from '../utils/permissions';
import { formatApiTaskTypeTemplate } from '../utils/taskTypes';
import { PartialWithRequired } from '../utils/types';
import { ClusterId } from './clusters';
import { alerting, success } from './snacks';
import { TaskMetadata } from './tasks';

export type TaskTypeId = string;
export type TaskType = ItemWithPermissions & {
  id: TaskTypeId;
  // no cluster_id = template
  cluster_id?: ClusterId;
  label: string;
  status: TaskTypeStatus;
  group_by?: string;
  parent_task_type_id?: TaskTypeId;
  task_count?: number;
  metadata: TaskMetadata[];
};

type State = {
  taskTypesByClusterId: Record<ClusterId, TaskType[]>;
  taskTypeTemplates: TaskType[];
};

const initialState: State = {
  taskTypesByClusterId: {},
  taskTypeTemplates: [],
};

const taskTypesSlice = createAppSlice({
  name: 'taskTypes',
  initialState,
  reducers: (create) => ({
    fetchAllTaskTypes: create.asyncThunk(
      async (_, { dispatch }) => {
        const taskTypes = (await alerting(getTaskTypes, { dispatch })) as TaskType[];
        return taskTypes.filter((t) => t.group_by !== 'phyto_group');
      },
      {
        fulfilled: (state, { payload }) => {
          state.taskTypeTemplates = [];
          state.taskTypesByClusterId = {};
          for (const taskType of payload) {
            if (taskType.cluster_id) {
              state.taskTypesByClusterId[taskType.cluster_id] ??= [];
              state.taskTypesByClusterId[taskType.cluster_id]!.push(taskType);
            } else {
              state.taskTypeTemplates.push(formatApiTaskTypeTemplate(taskType));
            }
          }
        },
      },
    ),
    createTaskType: create.asyncThunk(
      async (taskType: Partial<TaskType>, { dispatch }) => {
        const newTaskType = (await alerting(() => addTaskType(taskType), { dispatch })) as TaskType;
        success('TaskType.snack_create', { dispatch });
        return newTaskType;
      },
      {
        fulfilled: (state, { payload }) => {
          if (payload.cluster_id) {
            state.taskTypesByClusterId[payload.cluster_id] ??= [];
            state.taskTypesByClusterId[payload.cluster_id]?.push(payload);
          }
        },
      },
    ),
    updateTaskType: create.asyncThunk(
      async (
        taskType: PartialWithRequired<TaskType, 'id'> & { previous_metadata?: TaskType['metadata'] },
        { dispatch },
      ) => {
        if (taskType.metadata) {
          const updatedMetadata = taskType.metadata.filter((metadata) => metadata.id);
          for (const metadata of updatedMetadata) {
            await alerting(() => updateMetadataInTaskType(taskType.id, metadata), { dispatch });
          }

          const addedMetadata = taskType.metadata.filter((metadata) => !metadata.id);
          for (const metadata of addedMetadata) {
            await alerting(() => addMetadataToTaskType(taskType.id, metadata), { dispatch });
          }

          const removedMetadata = (taskType.previous_metadata || []).filter(
            (metadata) => !taskType.metadata?.find((updatedMetadata) => updatedMetadata.id === metadata.id),
          );
          for (const metadata of removedMetadata) {
            await alerting(() => removeMetadataFromTaskType(taskType.id, metadata.id), { dispatch });
          }
        }

        const newTaskType = (await alerting(() => updateApiTaskType(taskType), { dispatch })) as TaskType;

        success('TaskType.snack_update', { dispatch });
        return newTaskType;
      },
      {
        fulfilled: (state, { payload }) => {
          if (payload.cluster_id) {
            state.taskTypesByClusterId[payload.cluster_id] ??= [];
            state.taskTypesByClusterId[payload.cluster_id] = [
              ...state.taskTypesByClusterId[payload.cluster_id]!.filter((taskType) => taskType.id !== payload.id),
              payload,
            ];
          }
        },
      },
    ),
    archiveTaskType: create.asyncThunk(
      async (payload: { taskTypeId: TaskTypeId; isCurrentlyArchived: boolean }, { dispatch }) => {
        await dispatch(
          updateTaskType({
            id: payload.taskTypeId,
            status: payload.isCurrentlyArchived ? TASK_TYPE_STATUS.ACTIVE : TASK_TYPE_STATUS.ARCHIVED,
          }),
        );
      },
      {},
    ),
    deleteTaskType: create.asyncThunk(
      async (payload: { taskTypeId: TaskTypeId; clusterId: ClusterId }, { dispatch }) => {
        await alerting(() => deleteApiTaskType(payload.taskTypeId), { dispatch });
        success('TaskType.snack_delete', { dispatch });
      },
      {
        fulfilled: (state, { meta: { arg } }) => {
          if (arg.clusterId) {
            state.taskTypesByClusterId[arg.clusterId] ??= [];
            state.taskTypesByClusterId[arg.clusterId] = state.taskTypesByClusterId[arg.clusterId]!.filter(
              (taskType) => taskType.id !== arg.taskTypeId,
            );
          }
        },
      },
    ),
  }),
  selectors: {
    selectTaskTypesByClusterId: (state) => state.taskTypesByClusterId,
    selectTaskTypeTemplates: (state) => state.taskTypeTemplates,
  },
});

export const { fetchAllTaskTypes, createTaskType, updateTaskType, archiveTaskType, deleteTaskType } =
  taskTypesSlice.actions;
export const { selectTaskTypesByClusterId, selectTaskTypeTemplates } = taskTypesSlice.selectors;

export default taskTypesSlice.reducer;
