import { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { MapProvider } from '../components/map/contexts/map';
import { ErrorBoundaryContainer } from '../containers/ErrorBoundaryContainer';
import { lazyWithRetry } from './lazyWithRetry';

const ParcelRoutes = lazyWithRetry(() => import('./ParcelRoutes'));
const EquipmentRoutes = lazyWithRetry(() => import('./EquipmentRoutes'));
const DeviceRoutes = lazyWithRetry(() => import('./DeviceRoutes'));
const DriversRoutes = lazyWithRetry(() => import('./DriversRoutes'));
const TasksRoutes = lazyWithRetry(() => import('./TasksRoutes'));
const ReportsRoutes = lazyWithRetry(() => import('./ReportsRoutes'));
const AdminRoutes = lazyWithRetry(() => import('./AdminRoutes'));
const Settings = lazyWithRetry(() => import('../containers/SettingsContainer'));
const Plans = lazyWithRetry(() => import('../containers/PlansContainer'));
const PartnerToken = lazyWithRetry(() => import('../utils/PartnerToken'));
const Dashboard = lazyWithRetry(() => import('../containers/DashboardContainer'));
const UnlinkedUser = lazyWithRetry(() => import('../utils/UnlinkedUser'));

function Routes() {
  return (
    <ErrorBoundaryContainer>
      <Suspense fallback={<div />}>
        <Switch>
          <Route path="/parcels" component={ParcelRoutes} />
          <Route path="/equipments" component={EquipmentRoutes} />
          <Route path="/devices" component={DeviceRoutes} />
          <Route path="/drivers" component={DriversRoutes} />
          <Route path="/tasks" component={TasksRoutes} />
          <Route path="/reports" component={ReportsRoutes} />
          <Route path="/admin" component={AdminRoutes} />
          <Route
            path="/plans"
            render={() => (
              <MapProvider id="plans-map" allowAnyZoom>
                <Plans />
              </MapProvider>
            )}
          />
          <Route path="/settings" component={Settings} />
          <Route path="/smag_auth_callback" component={PartnerToken} partnerName="smag" />
          <Route path="/unlinked" component={UnlinkedUser} />
          <Route component={Dashboard} />
        </Switch>
      </Suspense>
    </ErrorBoundaryContainer>
  );
}

export default Routes;
