import styled from 'styled-components';
import { colors } from '@karnott/colors';
import { pixelSize, pixelSpacing } from '@karnott/theme';

export const InformationText = styled.div<{
  error?: boolean;
  warning?: boolean;
  noMargin?: boolean;
}>`
  background-color: ${({ error, warning }) =>
    error ? colors('red') : warning ? colors('orange') : colors('grey', 600)};
  color: ${colors('white')};
  font-size: ${pixelSize('regular')};
  margin-top: ${({ noMargin }) => (noMargin ? 0 : pixelSpacing('small'))};
  padding: ${pixelSpacing('small')};
  border-radius: 4px;
`;
