import { Characteristic } from '../components/task_types/modals/EditTaskTypeModal';
import { TASK_METADATA_QUESTION_FORMAT, TASK_METADATA_QUESTION_TYPE } from '../constants/taskConstants';
import { TASK_TYPE_STATUS } from '../constants/taskTypeConstants';
import { TaskType } from '../slices/taskTypes';
import { TaskMetadata } from '../slices/tasks';

export function isTaskTypeArchived(taskType: TaskType) {
  return taskType.status === TASK_TYPE_STATUS.ARCHIVED;
}

export function getCharacteristicsFromMetadata(metadata: TaskMetadata[]): Characteristic[] {
  return metadata
    .toSorted((a, b) => a.position - b.position)
    .map((metadata) => {
      const definedResponses = metadata.defined_responses || [];
      const type =
        metadata.response_format === TASK_METADATA_QUESTION_FORMAT.OPEN
          ? metadata.response_type === TASK_METADATA_QUESTION_TYPE.NUMBER
            ? 'number'
            : 'string'
          : metadata.response_format === TASK_METADATA_QUESTION_FORMAT.SINGLE_CLOSED
            ? 'options'
            : metadata.response_format === TASK_METADATA_QUESTION_FORMAT.YES_OR_NO
              ? 'yes_no'
              : 'string';
      return {
        id: metadata.id,
        label: metadata.title || '',
        mandatory: metadata.mandatory || false,
        type,
        options: type === 'options' ? definedResponses : [''],
      };
    });
}

export function getMetadataFromCharacteristics(characteristics: Characteristic[]): TaskMetadata[] {
  return characteristics.map((characteristic, i) => ({
    id: characteristic.id,
    title: characteristic.label,
    mandatory: characteristic.mandatory,
    response_format:
      characteristic.type === 'number' || characteristic.type === 'string'
        ? TASK_METADATA_QUESTION_FORMAT.OPEN
        : characteristic.type === 'yes_no'
          ? TASK_METADATA_QUESTION_FORMAT.YES_OR_NO
          : TASK_METADATA_QUESTION_FORMAT.SINGLE_CLOSED,
    response_type:
      characteristic.type === 'number' ? TASK_METADATA_QUESTION_TYPE.NUMBER : TASK_METADATA_QUESTION_TYPE.STRING,
    defined_responses: characteristic.type === 'options' ? characteristic.options : [],
    position: i,
  }));
}

export function formatApiTaskTypeTemplate(template: TaskType): TaskType {
  return {
    ...template,
    label: template.label.slice(0, 1).toLocaleUpperCase() + template.label.slice(1),
    metadata: template.metadata.map((metadata) => ({
      ...metadata,
      title: metadata.title.slice(0, 1).toLocaleUpperCase() + metadata.title.slice(1),
    })),
  };
}
