import { createAppSlice } from '.';
import {
  addParcelToTask,
  addTaskMetadataResponses,
  createTask,
  deleteTask as deleteApiTask,
  getTask,
  getTaskFile,
  getTaskMetadata,
  getTasks,
  patchParcelInTask,
  patchTask,
  postProductUsageInTask,
  removeParcelFromTask,
  sendPDFTaskFile,
} from '../api/api';
import {
  PhytoTaskType,
  TASK_STATUS,
  TaskMetadataQuestionFormat,
  TaskMetadataQuestionType,
  TaskStatus,
} from '../constants/taskConstants';
import { objectMap } from '../utils';
import { stringifyFilters } from '../utils/filters';
import { ItemWithPermissions } from '../utils/permissions';
import { PartialWithRequired } from '../utils/types';
import { ClusterId } from './clusters';
import { CropId } from './crops';
import { EquipmentId, EquipmentModelId } from './equipments';
import { ParcelId } from './parcels';
import { MixtureId, ProductUsageId } from './phyto';
import { alerting, success } from './snacks';
import { TaskTypeId } from './taskTypes';
import { UserId } from './users';

export type TaskId = string;
type TaskMetadataId = string;

export type TaskMetadata = {
  id?: TaskMetadataId;
  task_type?: string;
  task_type_id?: TaskTypeId;
  readable_id?: string;
  title: string;
  response_format: TaskMetadataQuestionFormat;
  response_type: TaskMetadataQuestionType;
  defined_responses?: string[];
  group_by?: string;
  default_responses?: string[];
  responses?: string[];
  mandatory: boolean;
  position: number;
};

type TaskProductUsage = {
  product_usage_id: ProductUsageId;
  dose: number;
  dose_unit: string;
  default_dose: number;
  is_customized: boolean;
  total_quantity: number;
  ratio: number;
  total_unit: string;
  batch_number?: string;
};

type TaskParcel = {
  task_id: TaskId;
  parcel_id: ParcelId;
  completion_date?: string;
  to_date?: string;
  position?: number;
  parcel_area: number;
  worked_area?: number;
  goal_area: number;
  name: string;
  geometry_area: string;
  product_usages?: TaskProductUsage[];
};

type TaskWaterData = {
  total_dose: number;
  total_quantity: number;
};

type TaskAssignedUser = {
  user_id: UserId;
  email_address: string;
  fullname: string;
};

export type Task = ItemWithPermissions & {
  id: TaskId;
  name?: string;
  equipment_model_id: EquipmentModelId;
  owner_cluster_id: ClusterId;
  status: TaskStatus;
  client_cluster_id: ClusterId;
  mixture_id?: MixtureId;
  total_dose?: number;
  type: PhytoTaskType;
  owner_name: string;
  client_name: string;
  metadata: TaskMetadata[];
  file_generated: boolean;
  total_quantity?: number;
  product_usages?: TaskProductUsage[];
  parcels?: TaskParcel[];
  water_data?: TaskWaterData;
  crop_range_id?: CropId;
  equipment_instance_ids?: EquipmentId[];
  assigned_to_ids?: UserId[];
  equipment_instance_id?: EquipmentId;
  assigned_to_id?: UserId;
  assigned_to_fullname?: string;
  assigned_to_email?: string;
  assigned_users?: TaskAssignedUser[];
  completion_date?: string;
  from_date?: string;
  to_date?: string;
  mail_count?: number;
};

type TaskFilters = {
  client_cluster_id?: ClusterId[];
  crop_range_id?: CropId[];
  equipment_id?: EquipmentId[];
  from_completion_date?: string;
  to_completion_date?: string;
  status?: TaskStatus;
};

type State = {
  tasks: Task[];
  loading: boolean;
  lastFetched: Task | null;
  metadataByType: Partial<Record<PhytoTaskType, TaskMetadata[]>>;
};

const initialState: State = {
  tasks: [],
  loading: true,
  lastFetched: null,
  metadataByType: {},
};

const tasksCache = new Map<string, Task[]>();

function areFiltersCached(filters: TaskFilters) {
  if (filters.status !== TASK_STATUS.DONE) {
    delete filters.from_completion_date;
    delete filters.to_completion_date;
  }

  const key = stringifyFilters(filters);
  return tasksCache.has(key) ? key : null;
}

function receiveTask(state: State, task: Task) {
  state.lastFetched = task;
  state.tasks = state.tasks.filter((t) => t.id !== task.id);
  state.tasks.push(task);
}

const tasksSlice = createAppSlice({
  name: 'tasks',
  initialState,
  reducers: (create) => ({
    fetchTasks: create.asyncThunk(
      async (filters: TaskFilters, { signal, dispatch }) => {
        const tasks = (await alerting(() => getTasks(filters, signal), { dispatch })) as Task[] | undefined;
        if (tasks) {
          const key = stringifyFilters(filters);
          tasksCache.set(key, tasks);
        }
        return tasks || [];
      },
      {
        pending: (state, { meta: { arg } }) => {
          const key = areFiltersCached(arg);
          if (key) {
            // immediately load cached tasks without pending state
            state.tasks = tasksCache.get(key)!;
          } else {
            state.loading = true;
          }
        },
        fulfilled: (state, { payload }) => {
          state.tasks = payload;
        },
        settled: (state, { meta }) => {
          // if the request was aborted, another one is coming right after so we don’t hide
          // the loader until the next request completes (or fails)
          if (meta.requestStatus !== 'rejected' || !meta.aborted) {
            state.loading = false;
          }
        },
      },
    ),
    fetchTask: create.asyncThunk(
      async (taskId: TaskId, { dispatch }) => {
        const task = (await alerting(() => getTask(taskId), { dispatch })) as Task;
        return task;
      },
      {
        fulfilled: (state, { payload }) => {
          receiveTask(state, payload);
        },
      },
    ),
    addTask: create.asyncThunk(
      async (task: Partial<Task>, { dispatch }) => {
        const newTask = (await alerting(() => createTask(task), { dispatch })) as Task;
        success('CreateTaskModal.success_snack', { dispatch });
        return newTask;
      },
      {
        fulfilled: (state, { payload }) => {
          receiveTask(state, payload);
        },
      },
    ),
    updateTask: create.asyncThunk(
      async (
        task: PartialWithRequired<Task, 'id'> & {
          parcels?: {
            created: {
              id: ParcelId;
              computedArea: number;
              position: number;
              fromDate: string;
            }[];
            edited: {
              id: ParcelId;
              computedArea: number;
              position: number;
            }[];
            deleted: {
              id?: ParcelId;
              parcel_id?: ParcelId;
            }[];
          };
          metadata?: Record<string, TaskMetadata[]>;
        },
        { dispatch },
      ) => {
        // update task parcels
        const promises: Promise<unknown>[] = [];
        if (task.parcels) {
          for (const parcel of task.parcels.created) {
            promises.push(
              addParcelToTask(task.id, parcel.id, {
                goal_area: task.status === TASK_STATUS.TO_DO ? parcel.computedArea : undefined,
                worked_area: task.status === TASK_STATUS.TO_DO ? undefined : parcel.computedArea,
                position: parcel.position,
                from_date: parcel.fromDate,
              }),
            );
          }
          for (const parcel of task.parcels.edited) {
            promises.push(
              patchParcelInTask(task.id, parcel.id, {
                goal_area: task.status === TASK_STATUS.TO_DO ? parcel.computedArea : undefined,
                worked_area: task.status === TASK_STATUS.TO_DO ? undefined : parcel.computedArea,
                position: parcel.position,
              }),
            );
          }
          for (const parcel of task.parcels.deleted) {
            promises.push(removeParcelFromTask(task.id, parcel.id || parcel.parcel_id));
          }
        }
        if (task.metadata) {
          promises.push(dispatch(addMetadataToTask({ taskId: task.id, metadataByGroup: task.metadata })));
        }

        const updatedTask = await alerting(
          async () => {
            // update parcels and metadata in parallel
            await Promise.all(promises);

            // update task
            let updatedTask = (await patchTask(
              task.id,
              objectMap(task, (v, k) => (k === 'parcels' || k === 'metadata' ? undefined : v)),
            )) as Task;

            // for dose calculation, we need to update product doses sequentially (because the last state depends on the previous updates)
            if (task.product_usages) {
              for (const usage of task.product_usages) {
                updatedTask = await postProductUsageInTask(task.id, usage.product_usage_id, usage);
              }
            }

            return updatedTask;
          },
          { dispatch },
        );

        success('Tasks.update_snack', { dispatch });

        return updatedTask;
      },
      {
        fulfilled: (state, { payload }) => {
          receiveTask(state, payload);
        },
      },
    ),
    deleteTask: create.asyncThunk(
      async (taskId: TaskId, { dispatch }) => {
        await alerting(() => deleteApiTask(taskId), { dispatch });
        success('Tasks.delete_snack', { dispatch });
      },
      {
        fulfilled: (state, { meta: { arg } }) => {
          state.tasks = state.tasks.filter((task) => task.id !== arg);
        },
      },
    ),
    fetchTaskMetadata: create.asyncThunk(
      async (taskType: PhytoTaskType, { dispatch }) => {
        const metadata = (await alerting(() => getTaskMetadata(taskType), { dispatch })) as TaskMetadata[];
        return metadata;
      },
      {
        fulfilled: (state, { payload, meta: { arg } }) => {
          state.metadataByType[arg] = payload;
        },
      },
    ),
    addMetadataToTask: create.asyncThunk(
      async (payload: { taskId: TaskId; metadataByGroup: Record<string, TaskMetadata[]> }, { dispatch }) => {
        const responses: { task_metadata_id: TaskMetadataId; responses: string[] }[] = [];
        for (const groups of Object.entries(payload.metadataByGroup)) {
          for (const question of groups[1]) {
            if (question.responses && question.id) {
              responses.push({ task_metadata_id: question.id, responses: question.responses });
            }
          }
        }
        const updatedTask = (await alerting(() => addTaskMetadataResponses(payload.taskId, responses), {
          dispatch,
        })) as Task;
        return updatedTask;
      },
      {
        fulfilled: (state, { payload }) => {
          receiveTask(state, payload);
        },
      },
    ),
    fetchTaskFile: create.asyncThunk(async (taskId: TaskId, { dispatch }) => {
      await alerting(() => getTaskFile(taskId), { dispatch });
    }),
    sendTaskFile: create.asyncThunk(
      async (payload: { taskId: TaskId; email: string }, { dispatch }) => {
        await alerting(() => sendPDFTaskFile(payload.taskId), { dispatch });
        success('Tasks.send_pdf_snack', { params: { email: payload.email }, dispatch });
        return payload.taskId;
      },
      {
        fulfilled: (state, { payload }) => {
          state.tasks = state.tasks.map((task) =>
            task.id === payload ? { ...task, mail_count: (task.mail_count || 0) + 1 } : task,
          );
        },
      },
    ),
  }),
});

export const {
  fetchTasks,
  fetchTask,
  addTask,
  updateTask,
  deleteTask,
  fetchTaskMetadata,
  addMetadataToTask,
  fetchTaskFile,
  sendTaskFile,
} = tasksSlice.actions;

export default tasksSlice.reducer;
