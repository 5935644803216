import {
  CSSProperties,
  MouseEvent as ReactMouseEvent,
  ReactNode,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Popover } from 'react-aria-components';
import styled, { css } from 'styled-components';
import { colors } from '@karnott/colors';
import { UIHooks } from '@karnott/hooks';
import { AddIcon, ChevronIcon, KIcon, SearchIcon } from '@karnott/icons';
import { fontFamily, pixelSize, pixelSpacing, size, spacing } from '@karnott/theme';
import { TooltipMessage } from '@karnott/tooltip';
import { Checkbox } from '../checkbox';
import { FormHooks } from '../effects';
import { InputEffects } from '../input';

const Container = styled.div<{
  full?: boolean;
}>`
  position: relative;
  font-family: ${fontFamily()};
  flex: ${({ full }) => (full ? 1 : 'none')};
  width: ${({ full }) => (full ? '100%' : 'auto')};
  display: flex;
`;

const Handler = styled.button<{
  disabled?: boolean;
  opened?: boolean;
  thin?: boolean;
}>`
  all: unset;
  flex: 1;
  display: flex;
  min-width: 0;
  gap: 5px;
  padding: ${({ thin }) => (thin ? `1px` : '')} ${pixelSpacing('small')};
  flex-direction: row;
  align-items: center;
  border: solid 1px ${colors('grey', 'light')};
  border-radius: ${pixelSpacing('xSmall')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  background-color: ${({ disabled, opened }) => {
    if (disabled) {
      return colors('grey', 'lighter');
    }
    if (opened) {
      return colors('grey');
    }
    return colors('white');
  }};
  transition: all 0.1s linear;
  &:hover {
    ${({ disabled }) =>
      !disabled &&
      `background-color: ${colors('grey', 'dark')};
    border-color: ${colors('grey', 'dark')};`}
  }
`;

const IconContainer = styled.div`
  margin-right: 10px;
`;

const TitleContainer = styled.div<{
  titleColor: string;
  thin?: boolean;
}>`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
  color: ${({ titleColor }) => titleColor};
  box-sizing: border-box;
  overflow: hidden;
  font-size: ${({ thin }) => pixelSize(thin ? 'small' : 'regular')};
  + div {
    height: 15px;
  }
`;

const ListPopover = styled(Popover)`
  display: flex;
`;

type Direction = 'top' | 'bottom';

type MenuProps = {
  $direction: Direction;
  $width?: string;
  handlerHeight: number;
  alignRight: boolean;
  managedPositioning?: boolean;
};
const Menu = styled.div<MenuProps>`
  ${({ managedPositioning }) =>
    managedPositioning
      ? null
      : css<MenuProps>`
          position: absolute;
          z-index: 1;
          ${({ $direction, handlerHeight }) => {
            const offset = handlerHeight + spacing('xSmall');
            return $direction === 'bottom'
              ? css`
                  top: ${offset}px;
                  max-height: 45vh;
                `
              : css`
                  bottom: ${offset}px;
                  max-height: 280px;
                `;
          }}
          ${({ alignRight }) =>
            !alignRight
              ? css`
                  left: 0;
                `
              : css`
                  right: 0;
                `}
        `}
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: stretch;
  padding: ${pixelSpacing('small')};
  box-shadow:
    0 3px 10px rgba(0, 0, 0, 0.02),
    0 -3px 10px rgba(0, 0, 0, 0.02);
  border: 1px solid ${colors('grey', 200)};
  border-radius: ${pixelSpacing('xSmall')};
  background-color: ${colors('white')};
  font-family: ${fontFamily()};
  box-sizing: border-box;
  min-width: ${({ $width }) => ($width ? 'inherit' : '100%')};
  width: ${({ $width }) => ($width ? $width : 'inherit')};
`;

const ListItems = styled.div`
  align-self: stretch;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  font-size: ${pixelSize()};
  > * {
    white-space: nowrap;
    transition: background-color 50ms linear;
    border-radius: 4px;
    &:hover {
      background-color: ${colors('grey', 100)};
    }
    min-height: ${spacing() + spacing('small')}px;
  }

  &:not(:empty):has(+ div) {
    margin-bottom: ${pixelSpacing('small')};
  }
`;

const CheckboxContainer = styled.div`
  padding: 0 ${pixelSpacing('xSmall')};
  padding-top: 4px;
  min-height: ${spacing() + spacing('small') - 4}px;
  box-sizing: content-box;
`;

const ListItem = styled.div<{
  selected: boolean;
}>`
  position: relative;
  box-sizing: border-box;
  padding: ${pixelSpacing('xSmall')} ${pixelSpacing('xSmall')};
  font-weight: ${({ selected }) => (selected ? 'bold' : 'normal')};
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  color: ${colors('black')};
  flex-shrink: 0;
  > * {
    pointer-events: none;
  }
`;

const ListItemTitle = styled.div`
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  margin-left: ${pixelSpacing('xSmall')};
  & > p {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
  }
`;

const BottomActionContainer = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${pixelSpacing('xSmall')};
  padding: ${pixelSpacing('small')};
  background-color: ${colors('orange')};
  color: ${colors('white')};
  cursor: pointer;
  font-size: ${pixelSize()};
`;

const SelectorsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: ${colors('grey', 'light')};
  font-size: ${pixelSize('small')};
  margin-bottom: ${pixelSpacing('small')};
`;

const Selector = styled.div`
  padding: ${pixelSpacing('xSmall')} ${pixelSpacing('small')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
  cursor: pointer;
`;

const InputContainer = styled.div`
  display: flex;
  gap: ${pixelSpacing('xSmall')};
  align-items: center;
  border: solid 1px ${colors('grey', 200)};
  border-radius: ${pixelSpacing('xSmall')};
  padding: ${pixelSpacing('small')};
  margin-bottom: ${pixelSpacing('small')};
  background-color: ${colors('grey', 200)};
`;

const Input = styled.input`
  padding: 0;
  font-size: ${pixelSize()};
  color: ${colors('grey', 'dark')};
  background-color: transparent;
  outline: none;
  border: none;
  width: 100%;
  height: 100%;
  vertical-align: center;
  font-family: ${fontFamily()};

  ::placeholder {
    color: ${colors('grey', 'light')};
  }
`;

const CreateButton = styled.button`
  all: unset;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  svg {
    circle {
      fill: ${({ disabled }) => (disabled ? colors('grey') : colors('green'))};
      stroke: ${({ disabled }) => (disabled ? colors('grey') : colors('green'))};
    }
  }
`;

const Title = styled.span``;

const Selection = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: bold;
`;

const RightIconContainer = styled.div<{
  isOpen: boolean;
}>`
  transition: transform 0.1s linear 0s;
  transform: ${({ isOpen }) => (isOpen ? `rotate(180deg)` : null)};
  display: flex;
  align-items: center;
`;

type ItemId = string | number;
type BaseItem = { id: ItemId };
type InputMode = 'search' | 'create';

type BaseListProps<Item extends BaseItem> = {
  /** Whether to align the items on the right side of the trigger, or the left side (default) */
  alignItemsOnRightSide?: boolean;
  /**
   * Function to compare two items. This is used to determine if an item is selected or not. By default, this compares
   * the items by reference
   */
  areItemsEquals?: (a: Item, b: Item) => boolean;
  /** An action to display at the bottom of the list, usually to add an item on the fly */
  bottomAction?: { fn: () => void; label: string };
  /** Callback when the user clicks on the "clear selection" button */
  clearSelection?: () => void;
  /** Label of the "clear selection" button */
  clearSelectionLabel?: string;
  /** Styles to apply to the popup container */
  containerStyle?: CSSProperties;
  /** Getter for the color of an item */
  getItemColor?: (item: Item) => string;
  /**
   * Defines the `id` attribute for multiple elements:
   *
   * - For the global container: `id={id}`
   * - For the trigger button: `id={id + "-handler"}`
   * - For the list of items: `id={id + "-items"}`
   *
   * If undefined, none of these elements have an `id` attribute
   */
  id?: string;
  /** Whether the search input has a "create" button */
  inputMode?: InputMode;
  /**
   * Width of the list. If not set, the list will have the width of the trigger button or the width of the longest item,
   * whichever is larger.
   */
  listWidth?: string;
  /** Whether the user can select multiple items */
  multiple?: boolean;
  /** Callback when the popup container is clicked */
  onClick?: (e: ReactMouseEvent<HTMLDivElement>) => void;
  /** Callback when the creation button (+) is clicked */
  onCreate?: (label: string) => void;
  /** Callback when an item is clicked */
  onItemClick?: (item: Item) => void;
  /** Callback when an item is hovered. When the mouse leaves an item, the callback receives `null` */
  onItemHover?: (item: Item | null) => void;
  /** Placeholder of the search input */
  placeholder?: string;
  /**
   * Render function for the search when the user types a query that does not match any item. When the list is empty,
   * this is displayed as soon as the pop-up is opened
   */
  renderEmptyQueryPlaceholder?: (query: string) => ReactNode;
  /** Callback when the user clicks on the "select all" button */
  selectAll?: () => void;
  /** Label of the "select all" button */
  selectAllLabel?: string;
  /** Array of currently selected items (even when `multiple` is set to `false`) */
  selection: Item[];
  /** Callback when an item is selected or unselected */
  toggleItemSelection: (item: Item) => void;
  /** Whether to display a "clear selection" item at the start of the list */
  withClearSelectionItem?: boolean;
  /** Whether to display a search input */
  withSearch?: boolean;
  /**
   * Whether to display "select all" and "clear selection" buttons before the list. If this is `true`, both
   * `selectAllLabel` and `clearSelectionLabel` must be set
   */
  withSelectors?: boolean;
};

type ListProps<Item extends BaseItem> = BaseListProps<Item> & {
  /** Function to hide the popup */
  closeList: () => void;
  /** The list of displayed items in the popup */
  displayedList: Item[];
  /** Getter for the label of an item */
  getItemLabel: (item: Item) => ReactNode;
  /** The element used to handle the popup (usually a button) */
  handlerRef: RefObject<HTMLElement>;
  /** Whether the popup is shown */
  isOpen: boolean;
  /** Value of the search input */
  query?: string;
  /** Callback to set the query value */
  setQuery?: (query: string) => void;
  /** Whether the positioning is handled by an external library */
  managedPositioning?: boolean;
  /** The direction where the list opens */
  direction?: Direction;
};

/** Same as ListSelector, with a custom trigger button */
export function List<Item extends BaseItem>({
  alignItemsOnRightSide = false,
  areItemsEquals = (a, b) => a === b,
  bottomAction,
  clearSelection,
  clearSelectionLabel,
  closeList,
  containerStyle,
  direction = 'bottom',
  displayedList,
  getItemColor,
  getItemLabel,
  handlerRef,
  id,
  inputMode = 'search',
  isOpen,
  listWidth,
  managedPositioning = false,
  multiple = false,
  onClick: onContainerClick,
  onCreate,
  onItemClick,
  onItemHover,
  placeholder,
  query,
  renderEmptyQueryPlaceholder,
  selectAll,
  selectAllLabel,
  selection,
  setQuery = () => {},
  toggleItemSelection,
  withClearSelectionItem = false,
  withSearch = false,
  withSelectors = false,
}: ListProps<Item>) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [hovered, setHovered] = useState<Record<ItemId, boolean>>({});
  const listItemRef = useRef<Record<ItemId, HTMLElement | null>>({});

  const overOnItem = useCallback(
    (id: ItemId, state: boolean) => {
      onItemHover?.(state ? displayedList.find((item) => item.id === id) || null : null);
      const ref = listItemRef?.current[id];
      if (ref?.scrollWidth !== undefined && ref.scrollWidth > ref.offsetWidth) {
        setHovered((h) => ({ ...h, [id]: state }));
      }
    },
    [displayedList, onItemHover],
  );

  const resetOver = useCallback(() => {
    const resettedState: Record<ItemId, boolean> = {};
    Object.keys(listItemRef?.current).forEach((id) => {
      resettedState[id] = false;
    });
    setHovered(resettedState);
  }, [setHovered]);

  const onClick = useCallback(
    (item: Item) => {
      resetOver();
      toggleItemSelection(item);
      closeList();
      onItemClick && onItemClick(item);
    },
    [toggleItemSelection, closeList, onItemClick, resetOver],
  );

  const onQueryChangeCB = InputEffects.useInputChangeCallback(setQuery);

  useEffect(() => {
    if (isOpen) {
      inputRef.current && inputRef.current.focus();
    }
  }, [isOpen]);

  const isCreateButtonDisabled =
    inputMode === 'create' ? query === '' || !!(displayedList || []).find((i) => getItemLabel(i) === query) : true;

  if (!isOpen) return null;

  return (
    <Menu
      $direction={direction}
      $width={listWidth}
      handlerHeight={handlerRef.current?.clientHeight || 0}
      alignRight={alignItemsOnRightSide}
      style={containerStyle}
      onClick={onContainerClick}
      id={id}
      managedPositioning={managedPositioning}
    >
      {withSearch ? (
        <InputContainer>
          {inputMode === 'search' ? <SearchIcon size={size('large')} color={colors('grey', 'light')} /> : null}
          <Input ref={inputRef} onChange={onQueryChangeCB} value={query} placeholder={placeholder} />
          {inputMode === 'create' ? (
            <CreateButton
              disabled={isCreateButtonDisabled}
              onClick={() => {
                onCreate?.(query || '');
                closeList();
              }}
            >
              <AddIcon size={size('large')} color={colors('white')} />
            </CreateButton>
          ) : null}
        </InputContainer>
      ) : null}

      {withSelectors ? (
        <SelectorsContainer>
          <Selector onClick={selectAll}>{selectAllLabel}</Selector>/
          <Selector onClick={clearSelection}>{clearSelectionLabel}</Selector>
        </SelectorsContainer>
      ) : null}

      {selection?.length === undefined || selection?.length === null ? null : (
        <ListItems id={id ? `${id}-items` : undefined}>
          {withClearSelectionItem && clearSelection && (displayedList.length !== 0 || selection?.length) ? (
            <ListItem
              selected={!selection || selection.length === 0}
              onClick={() => {
                clearSelection();
                if (!multiple) {
                  closeList();
                }
              }}
            >
              <ListItemTitle>{clearSelectionLabel}</ListItemTitle>
            </ListItem>
          ) : null}

          {displayedList && displayedList.length === 0
            ? renderEmptyQueryPlaceholder?.(query || '')
            : displayedList.map((item, index) => {
                const itemLabel = getItemLabel(item);
                const itemColor = getItemColor?.(item);
                const selected = !!(selection || []).find((other) => areItemsEquals(item, other));
                return multiple ? (
                  <CheckboxContainer
                    key={item.id}
                    onMouseEnter={() => onItemHover?.(item)}
                    onMouseLeave={() => onItemHover?.(null)}
                    onClick={(e) => {
                      e.stopPropagation();
                      // if the click is on the element itself, but not its child
                      if (e.target instanceof Element && e.target.classList.contains('list-checkbox-container')) {
                        e.preventDefault();
                      }
                    }}
                    className="list-checkbox-container"
                    data-testid={id ? `${id}-item` : undefined}
                  >
                    <Checkbox
                      checked={selected}
                      label={itemLabel}
                      id={String(item.id)}
                      checkedColor={itemColor}
                      defaultColor={itemColor}
                      color={itemColor}
                      hoverTextOrientation={index === 0 ? 'bottom' : 'top'}
                      onChange={() => {
                        toggleItemSelection(item);
                        onItemClick && onItemClick(item);
                      }}
                    />
                  </CheckboxContainer>
                ) : (
                  <ListItem
                    key={item.id}
                    selected={selected}
                    onClick={() => onClick(item)}
                    onFocus={() => overOnItem(item.id, true)}
                    onMouseEnter={() => overOnItem(item.id, true)}
                    onMouseLeave={() => overOnItem(item.id, false)}
                    data-testid={id ? `${id}-item` : undefined}
                  >
                    <ListItemTitle>
                      {hovered[item.id] && typeof itemLabel === 'string' && (
                        <TooltipMessage orientation={index === 0 ? 'bottom' : 'top'} message={itemLabel} />
                      )}
                      <div ref={(el) => (listItemRef.current[item.id] = el)}>{itemLabel}</div>
                    </ListItemTitle>
                  </ListItem>
                );
              })}
        </ListItems>
      )}

      {bottomAction ? (
        <BottomActionContainer
          onClick={() => {
            bottomAction.fn();
            closeList();
          }}
          type="button"
        >
          {bottomAction.label}
        </BottomActionContainer>
      ) : null}
    </Menu>
  );
}

type ListSelectorProps<Item extends BaseItem> = BaseListProps<Item> & {
  /** Whether or not the list is disabled */
  disabled?: boolean;
  /** Set the width of the container to 100%. This sets the size of the trigger button as well as the list */
  full?: boolean;
  /** Getter for the intrinsic label of an item. Allows search on a value when the item label is not only a string */
  getItemLabel: (item: Item) => string;
  /** Getter for the selected state label of the trigger button */
  getSelectionLabel?: (selection: Item[]) => string;
  /** Karnott icon to display in the trigger button */
  Icon?: KIcon;
  /** The items to display in the list. The items must be objects containing at least an id property. */
  list: Item[];
  /**
   * Callback when the user types in the search input. The callback receives the query and the items displayed as an
   * argument. The callback is also called when the list closes, if the input was not empty.
   */
  onSearchChange?: (query: string, displayedList: Item[]) => void;
  /** Render function for the label of an item. This is used to display the item in the list, can be jsx */
  renderItemLabel?: (item: Item) => ReactNode;
  /** Display a thin trigger button */
  thin?: boolean;
  /** Default (no selection) label of the trigger button */
  title?: string;
};

/**
 * A pop-up list of items that can be selected. The user can either select one or multiple items. The placement (top or
 * bottom) as well as the height is managed automatically to always fully fit in the window.
 */
export function ListSelector<Item extends BaseItem>({
  alignItemsOnRightSide = false,
  areItemsEquals = (a, b) => a === b,
  bottomAction,
  clearSelection,
  clearSelectionLabel,
  containerStyle,
  disabled = false,
  full = false,
  getItemColor,
  getItemLabel,
  getSelectionLabel,
  Icon,
  id,
  inputMode = 'search',
  list,
  listWidth,
  multiple = false,
  onCreate,
  onItemClick,
  onItemHover,
  onSearchChange,
  placeholder,
  renderEmptyQueryPlaceholder,
  renderItemLabel = getItemLabel,
  selectAll,
  selectAllLabel,
  selection,
  thin = false,
  title,
  toggleItemSelection,
  withClearSelectionItem = false,
  withSearch = false,
  withSelectors = false,
}: ListSelectorProps<Item>) {
  const [triggerRef, refHovered] = UIHooks.useHover<HTMLButtonElement>(disabled);
  const containerRef = useRef<HTMLDivElement>(null);
  const popoverRef = useRef<HTMLElement>(null);

  const [isOpen, openList, closeList, toggle] = UIHooks.useOpenCloseState(false);
  const [displayedList, query, setQuery] = FormHooks.useListQueryFilter(list, getItemLabel);
  const lastSearchUpdate = useRef('');
  useEffect(() => {
    if (lastSearchUpdate.current !== query) {
      onSearchChange?.(query, displayedList);
    }
    lastSearchUpdate.current = query;
  }, [displayedList, onSearchChange, query]);

  const [hovered, setHovered] = useState(false);
  const titleRef = useRef<HTMLSpanElement>(null);
  const setOver = useCallback(
    (state: boolean) => {
      if (titleRef.current?.scrollWidth !== undefined && titleRef.current.scrollWidth > titleRef.current.offsetWidth) {
        setHovered(state);
      }
    },
    [setHovered],
  );

  const label = useMemo(() => {
    return getSelectionLabel ? getSelectionLabel(selection) : '';
  }, [getSelectionLabel, selection]);

  const onRequestCloseCallback = useCallback(() => {
    setQuery('');
    closeList();
  }, [closeList, setQuery]);

  const titleColor = useMemo(() => {
    if (disabled) {
      return colors('grey', 'dark');
    }
    if (refHovered || isOpen) {
      return colors('white');
    }
    return colors('black');
  }, [refHovered, disabled, isOpen]);

  const handlerClick = useCallback(() => {
    if (!disabled) {
      toggle();
    }
  }, [disabled, toggle]);

  const handleDomClick = useCallback(
    (e: MouseEvent) => {
      if (
        !disabled &&
        e.target instanceof Element &&
        !(containerRef.current?.contains(e.target) || popoverRef.current?.contains(e.target))
      ) {
        onRequestCloseCallback();
      }
    },
    [containerRef, disabled, onRequestCloseCallback],
  );

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleDomClick, true);
    }
    return () => {
      document.removeEventListener('click', handleDomClick, true);
    };
  }, [handleDomClick, isOpen]);

  const toggleItemCB = useCallback(
    (item: Item) => {
      if (!multiple) {
        closeList();
      }
      toggleItemSelection(item);
    },
    [toggleItemSelection, multiple, closeList],
  );

  return (
    <Container ref={containerRef} full={full} id={id}>
      <Handler
        type="button"
        onMouseLeave={() => setOver(false)}
        onMouseOver={() => setOver(true)}
        onFocus={() => setOver(true)}
        onClick={handlerClick}
        opened={isOpen}
        disabled={disabled}
        ref={triggerRef}
        thin={thin}
        id={id ? `${id}-handler` : undefined}
      >
        {hovered && <TooltipMessage orientation="top" message={label} />}

        {Icon ? (
          <IconContainer>
            <Icon size={16} color={titleColor} />
          </IconContainer>
        ) : null}

        <TitleContainer titleColor={titleColor} thin={thin}>
          {selection?.length > 0 ? <Selection ref={titleRef}>{label}</Selection> : <Title>{title}</Title>}
        </TitleContainer>

        <RightIconContainer isOpen={isOpen}>
          <ChevronIcon size={18} color={titleColor} />
        </RightIconContainer>
      </Handler>

      <ListPopover
        triggerRef={triggerRef}
        isOpen={isOpen}
        onOpenChange={(isOpen) => (isOpen ? openList() : closeList())}
        maxHeight={400}
        offset={spacing('xSmall')}
        placement={alignItemsOnRightSide ? 'bottom end' : 'bottom start'}
        isNonModal
        ref={popoverRef}
        shouldCloseOnInteractOutside={() => false}
      >
        <List
          alignItemsOnRightSide={alignItemsOnRightSide}
          areItemsEquals={areItemsEquals}
          bottomAction={bottomAction}
          clearSelection={clearSelection}
          clearSelectionLabel={clearSelectionLabel}
          closeList={closeList}
          containerStyle={containerStyle}
          displayedList={displayedList}
          getItemColor={getItemColor}
          getItemLabel={renderItemLabel}
          handlerRef={containerRef}
          id={id}
          inputMode={inputMode}
          isOpen={isOpen}
          listWidth={full ? `${containerRef.current?.clientWidth}px` : listWidth}
          managedPositioning
          multiple={multiple}
          onCreate={onCreate}
          onItemClick={onItemClick}
          onItemHover={onItemHover}
          placeholder={placeholder}
          query={query}
          renderEmptyQueryPlaceholder={renderEmptyQueryPlaceholder}
          selectAll={selectAll}
          selectAllLabel={selectAllLabel}
          selection={selection}
          setQuery={setQuery}
          toggleItemSelection={toggleItemCB}
          withClearSelectionItem={withClearSelectionItem}
          withSearch={withSearch}
          withSelectors={withSelectors}
        />
      </ListPopover>
    </Container>
  );
}
